<template>
    <div>
        <div class="container">
            <div class="navigationMargin">
                <h2 class="pt-4 text-center">Terms and Conditions</h2>
                <h4 class="pt-4">Opći uvjeti, uvjeti rezervacije i uvjeti kupnje usluga putovanja i usluga
                    paket-aranžmana</h4>
                <p><strong>Uvodna napomena – </strong>Opći uvjeti, uvjeti rezervacije i uvjeti kupnje usluga putovanja i
                    usluga paket-aranžmana (dalje u tekstu: Opći uvjeti) sastavni su dio Ugovora o putovanju odnosno
                    vouchera sklopljenih između Turističke agencije MERAKI d.o.o. (dalje u tekstu Organizator) i
                    Putnika/ugovaratelja putovanja koji se prijavljuje za putovanje ili paket-aranžman.</p>
                <p>&nbsp;</p>
                <ol>
                    <li><strong> Prijava i ugovaranje putovanja u paket-aranžmanu</strong></li>
                </ol>
                <p>(1) Putnik se može prijaviti:</p>
                <p>– online na web stranicama <a href="http://www.balkanroads.co" target="_blank">www.balkanroads.co</a> i <a
                    href="http://www.balkanyachtadventure.co" target="_blank">www.balkanyachtadventure.co</a></p>
                <p>– telefonom</p>
                <p>– e-mailom i drugim sredstvima daljinske komunikacije te</p>
                <p>– kod drugih ovlaštenih agenata Organizatora</p>
                <p>(2) Ispunjavanjem osobnih podataka i plaćanjem (upisivanjem broja svoje kreditne ili bankovne kartice
                    na sigurnosni servis za internet plaćanje na web stranicama balkanroads.co i balkanyachtadventure.co
                    kod kupnje usluga putem sredstava daljinske komunikacije), smatra se da Putnik u cijelosti prihvatio
                    ove Opće uvjete.</p>
                <p>&nbsp;</p>
                <ol start="2">
                    <li><strong> Obveze Organizatora</strong></li>
                </ol>
                <p>(1) Ugovorom o putovanju u paket-aranžmanu obvezuje se Organizator pribaviti Putniku paket-aranžman,
                    a Putnik se obvezuje platiti mu za to ugovorenu cijenu.</p>
                <p>(2 )Organizator je dužan Putniku pružiti usluge koje imaju sadržaj i svojstva predviđene ugovorom i
                    skrbiti se o pravima i interesima Putnika, u skladu s poslovnim običajima u ovoj djelatnosti.</p>
                <p>(3) Organizator je dužan u razumnom roku prije započinjanja putovanja u paket aranžmanu, u pisanom
                    obliku ili drugom trajnom, putniku dostupnom, obliku obavijestiti putnika o: mjestu međuodredišta
                    ili izmjene prijevoznog sredstva te vremenu dolaska u to mjesto; njegovu mjestu u prijevoznom
                    sredstvu (broju odjeljka u spavaćim kolima, kabine na brodu i sl.); imenu i prezimenu, adresi i
                    broju telefona mjesnog zastupnika organizatora putovanja ili, ako ga nema, osobi kojoj se putnik
                    može obratiti u slučaju poteškoća ili, ako nema niti takve osobe, broju telefona ili drugom sredstvu
                    koje mu omogućuje kontakt s organizatorom putovanja; u slučaju putovanja maloljetnika, načinu
                    uspostave neposredne veze s njim ili za njega odgovornom osobom; mogućnosti sklapanja ugovora o
                    osiguranju kojim se osiguravaju troškovi raskida ugovora od strane putnika ili troškovi pomoći i
                    povratka putnika u mjesto polazišta u slučaju nesreće ili bolesti.</p>
                <p>(4) Organizator odgovara za svu štetu koju prouzroči putniku neispunjenjem, djelomičnim ispunjenjem
                    ili neurednim ispunjenjem obveza koje se odnose na organiziranje putovanja u paket aranžmanu
                    predviđenih ugovorom ili zakonom.</p>
                <p>&nbsp;</p>
                <ol start="3">
                    <li><strong> Obveze Putnika</strong></li>
                </ol>
                <p>(1) Putnik je dužan Organizatoru putovanja u paket aranžmanu platiti ugovorenu cijenu za putovanje u
                    vrijeme kako je ugovoreno, odnosno uobičajeno.</p>
                <p>(2) Putnik je dužan na zahtjev organizatora pravodobno dostaviti sve podatke potrebne za
                    organiziranje putovanja, a posebno za pribavljanje prijevoznih karata, rezervaciju smještaja te
                    isprave potrebne za prelazak preko granice.</p>
                <p>(3) Putnik je dužan brinuti se da on osobno, njegove osobne isprave i njegova prtljaga ispunjavaju
                    uvjete predviđene graničnim, carinskim, sanitarnim, monetarnim i drugim propisima.</p>
                <p>(4) Putnik odgovara za štetu što je prouzroči organizatoru putovanja neispunjenjem obveza koje za
                    njega proizlaze iz ugovora i odredaba ovoga Zakona.</p>
                <p>&nbsp;</p>
                <ol start="4">
                    <li><strong> Sadržaj ugovora o putovanju u paket aranžmanu</strong></li>
                </ol>
                <p>(1) U ugovoru o putovanju u paket-aranžmanu ili potvrdi o tom ugovoru mora biti naveden puni sadržaj
                    dogovora ugovornih strana, i sljedeće informacije:</p>
                <ol>
                    <li>a) o posebnim zahtjevima putnika koje je organizator prihvatio</li>
                    <li>b) o tome da je organizator:</li>
                    <li>odgovoran za pravilno izvršenje svih usluga putovanja obuhvaćenih ugovorom .</li>
                    <li>dužan pružiti pomoć ako se putnik nađe u poteškoćama u skladu s člankom 49. Zakona o pružanju
                        usluga u turizmu.
                    </li>
                    <li>c) o nazivu osiguravatelja – društva za osiguranje ili banke kod kojih je osigurana jamčevina za
                        slučaj nesolventnosti, njegove podatke za kontakt uključujući njegovu zemljopisnu adresu, adresu
                        e-pošte, broj telefona, osobni identifikacijski broj, broj izdane jamčevine (broj police
                        osiguranja ili bankarskog jamstva) i druge podatke potrebne za aktiviranje jamčevine te, prema
                        potrebi, naziv nadležnog upravnog tijela i podatke za kontakt središnje kontaktne točke iz
                        članka 98. Zakona o pružanju usluga u turizmu.
                    </li>
                    <li>d) ime, adresu, telefonski broj, osobni identifikacijski broj, adresu e-pošte i, prema potrebi,
                        broj telefaksa lokalnog predstavnika organizatora, kontaktne točke ili druge službe koji putniku
                        omogućuju da brzo stupi u kontakt s organizatorom i učinkovito komunicira s njime, kako bi
                        zatražio pomoć ako se nađe u poteškoćama ili kako bi prijavio svaku neusklađenost koju utvrdi
                        tijekom izvršenja paket-aranžmana.
                    </li>
                    <li>e) o tome da putnik mora bez nepotrebnog odgađanja i vodeći računa o okolnostima prijaviti
                        organizatoru svaku nesukladnost koju utvrdi tijekom izvršenja usluge putovanja obuhvaćene
                        ugovorom o putovanju u paket-aranžmanu, sukladno članku 40. stavku 1. Zakona o pružanju usluga u
                        turizmu.
                    </li>
                    <li>f) kojima se omogućuje izravan kontakt s maloljetnikom ili osobom odgovornom za maloljetnika u
                        mjestu boravka maloljetnika, ako maloljetnik bez pratnje roditelja ili druge ovlaštene osobe
                        putuje na temelju ugovora o putovanju u paket-aranžmanu koji uključuje smještaj.
                    </li>
                    <li>g) o dostupnim unutarnjim postupcima za rješavanje pritužbi i mehanizmima za alternativno
                        rješavanje sporova vezanih za ugovor, o ovlaštenim tijelima za alternativno rješavanje sporova
                        kojim je trgovac obuhvaćen te o platformi za online rješavanje tih sporova, sukladno odredbama
                        posebnog zakona o alternativnom rješavanju potrošačkih sporova.
                    </li>
                    <li>h) o pravu putnika da ugovor prenese na drugog putnika u skladu s odredbama članka 34. Zakona o
                        pružanju usluga u turizmu.
                    </li>
                </ol>
                <p>(2) U odnosu na paket-aranžmane iz članka 7. točke 2. podtočke b) podpodtočke 5. Zakona o pružanju
                    usluga u turizmu trgovac kojem su podaci dostavljeni dužan je obavijestiti organizatora o sklapanju
                    ugovora koji dovodi do sastavljanja paket-aranžmana i organizatoru dati informacije potrebne da bi
                    on mogao ispuniti svoje obveze kao organizator.</p>
                <p>(3) Kad organizator od trgovca primi obavijest da je paket-aranžman sastavljen, dužan je odmah
                    putniku pružiti informacije iz stavka 1. ovoga članka na trajnom nosaču podataka.</p>
                <p>(4) Informacije iz stavaka 1. – 3. ovoga članka moraju biti pružene na jasan, razumljiv i lako
                    uočljiv način.</p>
                <p>(5) Organizator je dužan pravodobno, prije početka paket-aranžmana dostaviti putniku potrebne
                    priznanice, vaučere i karte, informacije o predviđenim vremenima polaska i, prema potrebi, roku za
                    registraciju, kao i o predviđenim vremenima za mjesta usputnog zaustavljanja, prometnim vezama i
                    dolasku.</p>
                <p>&nbsp;</p>
                <ol start="5">
                    <li><strong> Cijena aranžmana</strong></li>
                </ol>
                <p>(1) Prilikom prijave, Putnik uplaćuje punu cijenu aranžmana (po osobi) osim ako u programu nije
                    drukčije navedeno. Ukoliko je posebno programom predviđeno obročno plaćanje, program će biti određen
                    broj obroka i termini plaćanja, a u svakom slučaju razlika do pune cijene aranžmana uplaćuje se
                    najkasnije 21 dana prije početka putovanja osim ako u programu nije drukčije navedeno.</p>
                <p>(2) Cijena aranžmana (ukoliko na programu putovanja nije drugačije navedeno) u pravilu uključuje:
                    usluge prijevoza, usluge smještaja (hotelske i ugostiteljske usluge), najam plovila ili kabine u
                    plovilu, skippera, vodiča i organizaciju putovanja. Cijena može sadržavati i putničke pristojbe
                    zračnih luka te troškove stručnog i mjesnog vođenja samo ukoliko je to navedeno u programu
                    putovanja.</p>
                <p>(3) Cijena aranžmana ne uključuje (osim ako nije drugačije dogovoreno): fakultativne (neobavezne)
                    izlete i posjete, moguće troškove pribavljanja i izdavanja viza, ulaznice za objekte koji se
                    posjećuju. Sve vrste posebnih usluga (jednokrevetna soba, posebna prehrana i sl.) putnik sam plaća i
                    dužan ih je naručiti prilikom prijave putovanja. Ukoliko putnik traži posebnu uslugu za vrijeme
                    putovanja, plaća je na licu mjesta vodiču ili predstavniku Organizatora, u valuti zemlje u kojoj se
                    nalazi.</p>
                <p>(4) Objavljene cijene Organizatora rezultat su ugovora Organizatora s partnerima i ne moraju
                    odgovarati cijenama istaknutima na licu mjesta u destinaciji u kojoj putnik boravi.</p>
                <p>&nbsp;</p>
                <p><strong>5.1. Pravo na povećanje cijene i pravo na otkaz zbog povećanja cijena</strong></p>
                <p>(1) Nakon sklapanja ugovora o putovanju u paket-aranžmanu organizator smije povećati ugovorenu cijenu
                    samo ako je tim ugovorom izričito utvrđena mogućnost povećanja cijene i pravo putnika na sniženje
                    cijene u skladu sa stavkom 4. ovoga članka te je u ugovoru naveden način izračunavanja korekcija
                    cijene, i ako je povećanje cijene isključivo izravna posljedica promjene:</p>
                <ol>
                    <li>a) cijene prijevoza putnika koje su proizašle iz troškova goriva ili drugih izvora energije</li>
                    <li>b) visine poreza ili naknada za usluge putovanja obuhvaćene ugovorom koje određuju treće osobe
                        koje nisu izravno uključene u izvršenje paket-aranžmana, uključujući turističke poreze,
                        pristojbe za slijetanje ili naknade za ukrcaj ili iskrcaj u lukama i zračnim lukama ili
                    </li>
                    <li>c) deviznih tečajeva koji su relevantni za paket-aranžman.</li>
                </ol>
                <p>(2) Ako povećanje cijene iz stavka 1. ovoga članka premaši 8 % ugovorene ukupne cijene
                    paket-aranžmana, organizator nije ovlašten jednostrano je izmijeniti te se primjenjuje članak 36.
                    stavci od 2. do 6. Zakona o pružanju usluga u turizmu.</p>
                <p>(3) Povećanje cijene, bez obzira na njegovu veličinu, moguće je samo ako organizator na trajnom
                    nosaču podataka i najkasnije 20 dana prije početka paket-aranžmana o povećanju cijene na jasan i
                    razumljiv način obavijesti putnika, uz obrazloženje tog povećanja i izračun.</p>
                <p>(4) Ako je ugovorom o putovanju u paket-aranžmanu utvrđena mogućnost povećanja cijene, putnik ima
                    pravo na sniženje cijene koje odgovara bilo kojem sniženju troškova iz stavka 1. ovoga članka do
                    kojeg dođe nakon sklapanja ugovora i prije početka paket-aranžmana.</p>
                <p>(5) U slučaju sniženja cijene organizator ima pravo od iznosa povrata koji duguje putniku oduzeti
                    stvarno nastale administrativne troškove te je dužan na zahtjev putnika dati mu dokaze za te
                    administrativne troškove.</p>
                <p>&nbsp;</p>
                <p><strong>5.2. Popusti za djecu i druge pogodnosti</strong></p>
                <p>Popusti za djecu, kao i ostale pogodnosti posebno su prikazane kod opisa aranžmana, ukoliko programi
                    putovanja takve popuste i pogodnosti predviđaju.</p>
                <p>&nbsp;</p>
                <ol start="6">
                    <li><strong> Dokumentacija za putovanje</strong></li>
                </ol>
                <p>7 dana prije putovanja (polaska), ukoliko nije drugačije navedeno na programu, Organizator će Putniku
                    predati (dostaviti e-mailom ili sl.):</p>
                <ol>
                    <li>za aranžman uz vlastiti prijevoz: original voucher i eventualne pismene obavijesti</li>
                    <li>za aranžman autobusom: original voucher i program putovanja</li>
                    <li>za aranžman zrakoplovom: original voucher, program putovanja i zrakoplovnu kartu.</li>
                    <li><strong> Kategorizacija i opis usluga </strong></li>
                </ol>
                <p>(1) Ponuđeni smještajni kapaciteti u aranžmanima Organizatora opisani su prema službenoj nacionalnoj
                    kategorizaciji pojedine zemlje važećoj u trenutku izdavanja programa.</p>
                <p>(2) Prehrana, komfor kao i druge usluge u ponudi hotela/apartmana pod nadzorom su mjesnih turističkih
                    uprava pojedine zemlje, a standardi smještaja i usluga su različiti u odnosu na Republiku Hrvatsku i
                    nisu usporedivi.</p>
                <p>(3) Organizator ne preuzima odgovornost za usmenu ili pismenu informaciju koja je dobivena od strane
                    treće osobe a koja nije u skladu s opisom usluga i objekata u objavljenim programima.</p>
                <p>&nbsp;</p>
                <p><strong>7.1. Smještaj u sobe/apartmane </strong></p>
                <p>(1) Raspored sobe/apartmana određuje recepcija u mjestu boravka.</p>
                <p>(2) Ukoliko Putnik nije izričito ugovorio sobu/apartman posebnih oblika, prihvatit će bilo koju
                    službeno registriranu smještajnu jedinicu za izdavanje u pojedinom objektu opisanom u katalogu i
                    cjeniku.</p>
                <p>(3) Smještaj u sobi u pravilu nije moguć prije 16 sati na dan početka korištenja usluge, a isti se u
                    pravilu moraju napustiti do 10 sati na dan završetka korištenja usluga, osim ako se s pružateljem
                    pojedine usluge smještaja ne dogovori drugačije.</p>
                <p>Dolaske iza 20 sati nužno je najaviti unaprijed, osim ako nije drugačije naznačeno.</p>
                <p>(4) Boravak kućnih ljubimaca dozvoljen je u pojedinačnim slučajevima uz obaveznu doplatu.</p>
                <p>&nbsp;</p>
                <ol start="8">
                    <li><strong> Troškovi rezervacije na upit i troškovi promjene rezervacije</strong></li>
                </ol>
                <p>(1) Trošak rezervacije u trenutku kada nema slobodnih mjesta za traženi aranžman iznosi 80,00 kn.
                    Uplatom navedenog iznosa Organizator prihvaća prijavu rezervacije na upit i osigurava dodatno mjesto
                    (mjesto na upit). Ukoliko se dodatno mjesto osigura i prihvati od strane Putnika, prethodno uplaćeni
                    trošak rezervacije na upit uračunat će se u cijenu aranžmana.&nbsp; Ukoliko rezervaciju na upit
                    Putnika nije u mogućnosti potvrditi, uplaćeni iznos rezervacije na upit u cijelosti se vraća
                    Putniku.&nbsp; Odbijanjem ponuđene rezervacije Organizator zadržava uplaćeni iznos na ime pokrića
                    troškova rezervacije na upit.</p>
                <p>&nbsp;</p>
                <ol start="9">
                    <li><strong> Usluge rent-a-car</strong></li>
                </ol>
                <p>Rent-a-car je usluga iznajmljivanja osobnih vozila bez vozača.</p>
                <p>&nbsp;</p>
                <p><strong>9.1. Trošak rezervacije i kupnje automobila</strong></p>
                <p>(1) Organizator može na zahtjev Putnika, ukoliko već nema takvo vozilo u voznom parku, kupiti vozilo
                    za potrebe putovanja Putnika, kojeg će potom iznajmiti Putniku za potrebe putovanja/izvršenja
                    pustolovine.</p>
                <p>(2) Putnik može potražiti i izabrati vozilo putem online portala, tražilica ili na druge načine, a
                    Organizator odlučuje da li će pristati ili ne na zahtjev Putnika i kupiti vozilo.</p>
                <p>(3) Vrstu i model vozila može izabrati i Organizator te ga ponuditi Putniku, a Putnik može prihvatiti
                    ili odbiti prijedlog Organizatora.</p>
                <p>(4) Ukoliko Organizator ili Putnik bilo kojim putem pronađu potencijalno vozilo, Organizator će
                    stupiti u kontakt s vlasnikom vozila i u što kraćem vremenu (najkasnije u roku od 3 dana od stupanja
                    u kontakt s vlasnikom) mora obavijestiti Putnika o svim relevantnim karakteristikama vozila kao i o
                    cijeni, mjestu gdje se nalazi, nedostacima, eventualnim potrebnim popravcima i svemu ostalome što je
                    bitno za kupnju vozila i dovođenje vozila na dogovorenu lokaciju (u Rijeci ili drugu lokaciju koju
                    dogovore Putnik i Organizator). Ukoliko se Putnik odluči na kupnju vozila, Organizator ga mora prije
                    kupnje obavijestiti o dogovorenoj cijeni s vlasnikom vozila, te tek po izjavljenoj potvrdi Putnika
                    (učinjenoj pisanim putem) Organizator će kupiti vozilo.</p>
                <p>(5) Ukoliko Putnik prihvati kupoprodajnu cijenu za izabrano vozilo kojim će se služiti na putovanju,
                    dužan je u roku 3/7 dana od prihvata cijene, Organizatoru u cijelosti isplatiti akontaciju u visini
                    kupoprodajne cijene uvećanu za sve ostale troškove vezane za kupnju vozila (plaćanje poreza na
                    motorno vozilo prilikom prijenosa vlasništva, biljezi, formulari, eventualan trošak goriva povezan s
                    kupnjom vozila, cestarine kao i ostale troškove uzrokovane procesom pregleda i/ili kupnje vozila
                    itd.), a Organizator se obvezuje dostaviti Putniku račune za sve nastale troškove na njegov zahtjev,
                    osim ukoliko se s Organizatorom pismeno ne dogovori drugačije ovisno o situaciji i realnim
                    mogućnostima pregleda vozila i kupnje.</p>
                <p>(6) Putnik je dužan Organizatoru snositi i troškove vezane za pregled vozila i odlazak na lokaciju
                    gdje se vozilo oko čije se kupnje pregovara nalazi</p>
                <p>– 50,00 € u protuvrijednosti kuna po pregledu (jer pregled može trajati i cijeli dan ukoliko se
                    vozilo nalazi na udaljenoj lokaciji u Hrvatskoj.</p>
                <p>– 50,00 € u protuvrijednosti kuna po pregledu za svaki sljedeći dan koji Organizator iskoristi za
                    pregled i/ili kupnju.</p>
                <p>&nbsp;</p>
                <p><strong>9.2. Najam vozila</strong></p>
                <p>(1) Ukoliko Organizator na zahtjev Putnika kupi vozilo, isto vozilo će mu potom dati u
                    najam/korištenje za vrijeme putovanja. Cijena za dan najma/korištenja vozila ugovarat će se naknadno
                    pisanim putem.</p>
                <p>(2) Vozilo će se dati u najam pod uvjetom da barem jedan od Putnika, koji će upravljati vozilom, ima
                    važeću vozačku dozvolu sukladno propisima Republike Hrvatske (ako se radi o stranom državljaninu
                    isti mora posjedovati važeću međunarodnu vozačku dozvolu).</p>
                <p>&nbsp;</p>
                <p><strong>9.3. Depozit prilikom najma vozila</strong></p>
                <p>(1) Putnik je dužan prilikom preuzimanja vozila na korištenje za putovanje dati depozit u vrijednosti
                    300,00 €, a koji će mu biti vraćen na kraju putovanja ukoliko vrati vozilo u stanju u kakvom ga je i
                    preuzeo.</p>
                <p>Iznos na ime akontacije za kupnju vozila iz točke 8. Općih uvjeta, smatra se depozitom ukoliko iznos
                    premašuje 300,00 €. Ukoliko je iznos akontacije za kupnju vozila manji od 300,00 € Putnik je dužan
                    na doplatiti razliku do 300,00 €.</p>
                <p>(2) Depozit Organizator može koristiti za plaćanje obveza nastalih zbog korištenja vozila (npr. kazni
                    zbog prekršaja u prometu itd.) za koje je odgovoran Putnik, a ukoliko 300,00 € ne bi bilo dostatno
                    za pokriće navedenih troškova, Organizator je dužan platiti razliku do punog iznosa nastale
                    obveze</p>
                <p>&nbsp;</p>
                <p><strong>9.4. Pravo prvokupa vozila</strong></p>
                <p>(1) Organizator je dužan nakon završetka putovanja Putniku ponuditi pravo prvokupa vozila.</p>
                <p>(2) U slučaju da Putnik iskoristiti pravo prvokupa i odluči kupiti vozilo, kupoprodajna cijena će se
                    odrediti tako što će se na akontaciju u iznosu kupoprodajne cijene koja je plaćena za vozilo od
                    strane Organizatora prilikom kupnje, dodati svi troškovi koji su za Organizatoru proizašli iz kupnje
                    vozila (porez na motorno vozilo prilikom prijenosa vlasništva, biljezi, formulari, trošak goriva
                    povezan s kupnjom vozila, troškovi cestarine, trošak smještaja ukoliko je pregled trajao duže od
                    jednog dana, ostali troškovi uzrokovani procesom pregleda i/ili kupnje vozila), a umanjit će se za
                    cijenu koju je Putnik platio na ime najma vozila za vrijeme putovanja.</p>
                <p>&nbsp;</p>
                <p><strong>9.5. Odgovornost za nedostatke, kvarove vozila i oštećenja vozila</strong></p>
                <p>(1) Organizator je prilikom odabira vozila dužan Putnika izvijestiti o stanju vozila kojeg kupuje, te
                    ga upozoriti o svim relevantnim karakteristikama vozila kao i o cijeni, mjestu gdje se nalazi,
                    nedostacima, eventualnim potrebnim popravcima i svemu ostalome što je bitno za kupnju vozila i
                    dovođenje vozila na dogovorenu lokaciju (u Rijeci ili drugu lokaciju koju dogovore Putnik i
                    Organizator), te se smatra da je Putnik u trenutku kupnje vozila s prednjim upoznat.</p>
                <p>(2) Organizator ne odgovara za kvarove vozila koji potječu od nedostataka ili mana vozila na koje je
                    prilikom kupnje vozila i davanja na korištenje vozila priopćio i na koje je upozorio Putnika. Smatra
                    se da je Putnik prihvatio rizike kupnje vozila s određenim nedostacima i drugim manama, na koje ga
                    je upozorio Organizator prije kupnje, te se odriče potraživati od Organizatora bilo kakve troškove,
                    a niti naknadu štete koje su mu zbog nedostataka ili mana vozila izazvana tokom ugovorenog
                    putovanja, kao što se odriče prava potraživati zamjensko vozilo ili povrat plaćene naknade za
                    korištenje/najam vozila.</p>
                <p>(3) Putnik odgovara za trošak popravka svih oštećenja i neispravnosti vozila koje je izazvao tokom
                    korištenja vozila i potječu od korištenja vozila.</p>
                <p>&nbsp;</p>
                <ol start="10">
                    <li><strong>Paket aranžman na plovnim objektima nautičkog turizma</strong></li>
                </ol>
                <p><strong>10.1 Sadržaj paket aranžmana</strong></p>
                <p>(1) Paket aranžman na plovnim objektima obuhvaća usluge najma ležaja na brodu, prijevoz do Sarajeva i
                    nazad i sve ostale usluge naznačene na službenoj stranici Organizatora BALKAN YACHT ADVENTURE.</p>
                <p>(2) Putnik osim za sebe, može uplatiti paket aranžman i za druge osobe, u tom slučaju je obavezan
                    dostaviti njihove podatke najkasnije tjedan dana prije početka putovanja.</p>
                <p>(3) Ukoliko korisnik želi biti sam u kabini na brodu, dužan je platiti alikvotan dio ukupne cijene
                    jedne kabine u odnosu na ukupan broj kabina i ležećih mjesta na brodu. U slučaju da korisnik želi
                    kabinu dijeliti dužan je naći osobu s kojom će ju dijeliti ili pristati da mu/joj ju pronađe
                    Organizator.</p>
                <p>(4) Kabine u brodu imaju dva ležeća mjesta.</p>
                <p>(5) Putnik se obvezuje Organizatoru javiti svoje želje i preferencije vezano uz smještaj u kabinama
                    što je ranije moguće, a Organizator će učiniti sve što je u mogućnosti da ih zadovolji.</p>
                <p>(6) Organizator se ograđuje od svake odgovornosti u slučaju da je Putnik pristao da Organizator
                    pronađe osobu s kojom će Putnik biti smješten u kabini.</p>
                <p>&nbsp;</p>
                <p><strong>10.2. Cijena paket aranžmana</strong></p>
                <p>(1) Cijena paket aranžmana uključuje najam plovila te pristojbu za plovilo i njegovo korištenje.</p>
                <p>(2) U cijenu nisu uključeni troškovi goriva tijekom najma plovila i lučke, sidrišne i slične
                    pristojbe. Njih Putnik plaća sam za vrijeme putovanja ili nakon, ako je tako predviđeno.</p>
                <p>(3) Organizator prodaje paket aranžman, međutim u odnosu na najam brodice djeluje kao posrednik
                    između charter agencije koja iznajmljuje plovilo i Putnika.</p>
                <p>&nbsp;</p>
                <p><strong>10.3. Preuzimanje plovila</strong></p>
                <p>(1) Organizator se obvezuje da će Putniku predati ispravno i čisto plovilo na ugovorenom mjestu i u
                    ugovoreno vrijeme.</p>
                <p>(2) Ukoliko Organizatoru iz bilo kojeg razloga ne postupi po obvezi iz prethodnog stavka i stavi
                    Putniku na raspolaganje ugovoreno plovilo u ugovoreno vrijeme, obvezuje se u roku od 24 sata staviti
                    na raspolaganje zamjensko plovilo sličnih ili boljih karakteristika na ugovorenom mjestu.</p>
                <p>(3) U slučaju da se Organizator ne ispoštuje obvezu iz stavka 2. u ugovorenom roku Putnik ima pravo
                    raskinuti ugovor i tražiti povrat punog iznosa najamnine plovila.</p>
                <p>(4) Plovilo će se predati Putniku u ispravnom stanju, čisto i s punim tankovima goriva i vode, plinom
                    za kuhanje, posteljinom i ručnicima za sve članove posade, te opremom navedenom na check in listi.
                    Putnik je dužan prekontrolirati plovilo prilikom preuzimanja te o eventualnim nedostacima bez
                    odgađanja izvijestiti Organizatora koji će potom po potrebi izvijestiti charter agenciju, te ga je u
                    istom stanju potrebno i vratiti.</p>
                <p>(5) Eventualni prigovori podnose se prilikom primopredaje te se moraju napisati na check-in listi, a
                    nedostaci koji nisu utvrđeni prilikom primopredaje ne daju pravo Putniku da traži sniženje
                    najamnine. Zakašnjenje u podnošenju prigovora može biti opravdano samo u slučaju više sile, Putnik
                    mora uz prigovor Organizatora izvijestiti i o razlozima zašto prigovor nije podnio prilikom
                    primopredaje plovila.</p>
                <p>&nbsp;</p>
                <p><strong>10.4. Kaucija-depozit</strong></p>
                <p>(1) Prije primopredaje plovila Putnik mora u uredu charter agencije položiti kauciju u gotovini ili
                    slipovima VISA, AMEX, MASTER ili DINERS kreditnih kartica, iznosu kojeg će se odrediti u Ugovora o
                    paket aranžmanu obzirom na vrstu plovila.</p>
                <p>(2) Kaucija-depozit će se Putniku vratiti u punom iznosu nakon završetka putovanja i po završetku
                    check-outa, ako se ustanovi da je korisnik vratio neoštećeno i čisto plovilo s punim tankovima
                    goriva, u dogovoreno vrijeme i na dogovoreno mjesto.</p>
                <p>&nbsp;</p>
                <p><strong>10.5. Osiguranje plovila</strong></p>
                <p>(1) Policom osiguranja plovilo registrirano za čarter je osigurano punim kaskom, kojim su pokriveni
                    svi rizici i štete, uključujući osiguranje od odgovornosti vlasnika odnosno korisnika plovila, iznad
                    iznosa kaucije sve do pune vrijednosti plovila. Polica pokriva, obavezno osiguranje putnika od
                    posljedica nesretnog slučaja te obavezno osiguranje za štete nanesene trećim osobama. Osobna imovina
                    posade nije osigurana.</p>
                <p>(2) Ukoliko prilikom putovanja a za vrijeme trajanja najma nastane štetni događaj ili bilo koja vrsta
                    štete na plovilu, a Putnik odmah po nastanku ne prijavi najbližoj Lučkoj kapetaniji, za štetu koja
                    nastane zbog nepravovremenog prijavljivanja štete Putnik osobno odgovara Organizatoru i charter
                    agenciji.</p>
                <p>&nbsp;</p>
                <p><strong>10.6. Odgovornost i obveze Putnika:</strong></p>
                <p>(1) Putnik je dužan naknaditi Organizatoru sve materijalne i pravne troškove koje je potonji imao
                    prema charter agenciji ili trećim osobama a koji su uzrokovani skrivljenim ponašanjem Putnika.</p>
                <p>(2) Putnik je također odgovoran ukoliko tokom najma plovila zbog kršenja zakonskih propisa (npr.
                    komercijalnog ribarenja, vađenja antikviteta s morskog dna i sl.) ovlašteno tijelo zaplijeni
                    plovilo, te je obvezan o istome odmah obavijestiti Organizatora i charter agenciju.</p>
                <p>(3) Svaku havariju s drugim plovilom ili kopnom te eventualnu krađu plovila ili njegove opreme Putnik
                    je dužan odmah prijaviti najbližoj Lučkoj kapetaniji ili policijskoj postaji te zatražiti kopiju
                    izvještaja, te o tome obavijestiti Organizatora i charter agenciju.</p>
                <p>(4) Štetu nastalu na plovilu ili njegovoj opremi za vrijeme najma plovila snosi Putnik.</p>
                <p>(5) Putnik je tokom plovidbe obavezan kontrolirati razinu ulja u motoru te snosi trošak za eventualnu
                    štetu na motoru zbog nedostatka ulja.</p>
                <p>(6) Trup plovila pregledava se na kraju putovanja te Putnik snosi odgovornost za štetu nastalu na
                    trupu broda.</p>
                <p>(7) Putnik je obvezan ploviti u granicama hrvatskog teritorijalnog mora. Za plovidbu u druge zemlje
                    Organizator i charter agencija će na zahtjev Putnika pribaviti potrebnu dozvolu. Putnik mora imati
                    važeću dozvole za upravljanje plovilom, te se sklapanjem ugovora o putovanju u paket aranžmanu svi
                    Putnici obvezuju da će plovilom upravljati Putnik koji ima takva odobrenja, te da neće ploviti u
                    nepovoljnim vremenskim uvjetima.</p>
                <p>(8) Putniku je zabranjeno davati u podnajam, posuđivati, davati na uporabu ili korištenje plovilo
                    osobama koja nije upisana u popis posade plovila, također i sudjelovanje na regatama bez posebnog
                    odobrenja Organizatora i charter agencije.</p>
                <p>&nbsp;</p>
                <p><strong>10.7. Odustanak od putovanja:</strong></p>
                <p>(1) Ukoliko Putnik iz bilo kojeg razloga mora odustati od putovanja prije započetog putovanja, može
                    pronaći osobu koja će preuzeti njegova prava i obveze iz ugovora o putovanju u paket aranžmanu, uz
                    prethodni pristanak Organizatora.</p>
                <p>(2) Ukoliko Putnik ne pronađe osobu koja će preuzeti njegova prava i obveze iz ugovora o putovanju u
                    paket aranžmanu, Organizator će zadržati:</p>
                <p>– 30% od cijene paket aranžmana za odustajanje dva mjeseca prije početka putovanja</p>
                <p>– 50% od cijene paket aranžmana za odustajanje jedan mjesec prije početka putovanja</p>
                <p>– 100% od cijene paket aranžmana za odustajanje unutar 30 dana prije početkaputovanja</p>
                <p>– drugi iznos koji Putnik dogovori s Organizatorom</p>
                <p>&nbsp;</p>
                <p><strong>10.8. Najam skippera</strong></p>
                <p>(1) Skippera koji će upravljati plovilom dužan je osigurati Organizator, osim ukoliko Putnik ne želi
                    sam upravljati plovilom pod uvjetom da ima važeću dozvolu za upravljanje plovilom, ili već ima osobu
                    koja će upravljati plovilom.</p>
                <p>(2) U oba slučaja korisnik mora priložiti važeću ispravu kojom dokazuje da je stručan i kvalificiran
                    za upravljanje plovilom i policu osiguranja, tzv. skippersko osiguranje.</p>
                <p>&nbsp;</p>
                <ol start="11">
                    <li><strong> Pravo Putnika na raskid ugovora o putovanju i pravo odustajanja prije početka
                        putovanja</strong></li>
                </ol>
                <p>(1) Putnik može raskinuti ugovor o putovanju u paket-aranžmanu u bilo kojem trenutku prije početka
                    paket-aranžmana.</p>
                <p>(2) U slučaju iz stavka 1. ovoga članka Organizator gubi pravo na ugovorenu cijenu paket-aranžmana i
                    može od Putnika zahtijevati plaćanje primjerene naknade za raskid ugovora koju se može
                    opravdati.</p>
                <p>(3) Ukoliko Putnik otkaže aranžman Organizator ima pravo ukupne cijene aranžmana zadržati:</p>
                <p>– 10% od ugovorene cijene aranžmana a najmanje 375,00 kuna protuvrijednosti EUR, ukoliko putnik
                    otkaže putovanje do 30 dana prije polaska</p>
                <p>– 25% od ugovorene cijene aranžmana ukoliko putnik otkaže putovanje 29 – 22 dna prije polaska</p>
                <p>– 40% od ugovorene cijene aranžmana ukoliko putnik otkaže putovanje 21 – 15 dana prije polaska</p>
                <p>– 80% od ugovorene cijene aranžmana ukoliko putnik otkaže putovanje 14 – 8 dana prije polaska</p>
                <p>– 100% od ugovorene cijene aranžmana ukoliko putnik otkaže putovanje 7 – 0 dana prije polaska</p>
                <p>– 100% od ugovorene cijene aranžmana ukoliko putnik otkaže putovanje nakon polaska</p>
                <p>Navedeni troškovi otkaza primjenjuju se i na promjene datuma polaska ili smještajnog objekta, odnosno
                    tipa sobe/apartmana kao i na sve druge bitne promjene. Eventualni drugačiji uvjeti otkaza navedeni
                    su u programu i katalogu na koji se odnose. Ako Putnik koji otkaže putovanje nađe novog korisnika
                    iste rezervacije, a koji ispunjava sve predviđene uvjete putovanja i pod uvjetom da obavijesti
                    Organizatora, Organizator će Putniku obračunati i naplatiti samo troškove uzrokovane zamjenom
                    Putnika. Kod otkazivanja svih aranžmana ne isplaćuje se trošak nabave vize/a ni putnih isprava. U
                    slučaju da Putnik otkaže ili skrati putovanje zbog vremenskih uvjeta, Organizator će primijeniti
                    gore navedene uvjete zadržanja, a naknadne reklamacije neće uvažiti.</p>
                <p>(4) Na zahtjev putnika Organizator je dužan obrazložiti iznos naknade za raskid ugovora.</p>
                <p>(5) Ne dovodeći u pitanje odredbe stavaka 1. – 4. ovoga članka, putnik ima pravo raskinuti ugovor o
                    putovanju u paket-aranžmanu prije početka paket-aranžmana bez plaćanja bilo kakve naknade za raskid
                    ugovora u slučaju izvanrednih okolnosti koje se nisu mogle izbjeći, a koje su nastupile na odredištu
                    ili u njegovoj neposrednoj blizini i koje znatno utječu na ispunjenje paket-aranžmana ili koje
                    znatno utječu na prijevoz putnika na odredište.</p>
                <p>(6) U slučaju raskida ugovora iz stavka 5. ovoga članka putnik ima pravo na puni povrat svih plaćanja
                    izvršenih za paket-aranžman, ali nema pravo na dodatnu odštetu.</p>
                <p>(7) Organizator je dužan putniku vratiti sva plaćanja izvršena u korist putnika umanjena za
                    primjerenu naknadu za raskid ugovora, bez nepotrebnog odgađanja, a najkasnije u roku od 14 dana od
                    raskida ugovora o putovanju u paket-aranžmanu.</p>
                <p>(8) Ako je ugovor sklopljen izvan poslovnih prostorija, putnik ima pravo u roku od 14 dana nakon
                    sklapanja ugovora odustati od ugovora o putovanju u paket-aranžmanu bez navođenja razloga.</p>
                <p>&nbsp;</p>
                <ol start="12">
                    <li><strong> Pravo Organizatora na raskid ugovora o putovanju prije početka putovanja ili promjena
                        programa</strong></li>
                </ol>
                <p>(1) Organizator može raskinuti ugovor o putovanju u paket-aranžmanu prije početka paket-aranžmana i
                    putniku u cijelosti vratiti sva plaćanja primljena za paket-aranžman, bez obveze naknade štete
                    putniku, ako je broj osoba koje su prijavljene za paket-aranžman manji od najmanjeg broja navedenog
                    u ugovoru i ako organizator obavijesti putnika o raskidu ugovora unutar roka utvrđenog u ugovoru,
                    ali ne kasnije od:</p>
                <ol>
                    <li>20 dana prije početka paket-aranžmana za putovanja koja traju više od šest dana</li>
                    <li>7 dana prije početka paket-aranžmana za putovanja koja traju između dva i šest dana</li>
                    <li>48 sati prije početka paket-aranžmana za putovanja koja traju manje od dva dana.</li>
                </ol>
                <p>(2) Organizator može raskinuti ugovor o putovanju u paket-aranžmanu prije početka paket-aranžmana i
                    putniku u cijelosti vratiti sva plaćanja primljena za paket-aranžman, bez obveze naknade štete
                    putniku, ako organizatora u izvršenju ugovora spriječe izvanredne okolnosti koje se nisu mogle
                    izbjeći te ako putnika o raskidu ugovora obavijesti bez nepotrebnog odgađanja prije početka
                    paket-aranžmana.</p>
                <p>(3) U slučaju raskida ugovora iz stavaka 1. i 2. ovoga članka organizator gubi pravo na ugovorenu
                    cijenu paket-aranžmana i dužan je putniku vratiti sva plaćanja izvršena u korist putnika bez
                    nepotrebnog odgađanja, a najkasnije u roku od 14 dana od raskida ugovora o putovanju u
                    paket-aranžmanu</p>
                <p>&nbsp;</p>
                <ol start="13">
                    <li><strong> Odgovornost za štetu kad je pružanje pojedinih usluga organizator povjerio trećim
                        osobama</strong></li>
                </ol>
                <p>(1) Organizator koji je povjerio trećim osobama izvršenje usluga prijevoza, smještaja ili drugih
                    usluga vezanih za izvršenje putovanja, odgovara Putniku za štetu koja je nastala zbog potpunog ili
                    djelomičnog neizvršenja tih usluga, u skladu s propisima koji se na njih odnose.</p>
                <p>(2) Ali i kad su usluge izvršene u skladu s ugovorom i propisima koji se na njih odnose, Organizator
                    odgovara za štetu koju je putnik pretrpio u povodu njihova izvršenja, osim ako dokaže da se ponašao
                    kao pažljiv organizator putovanja pri izboru osoba koje su ih izvršile.</p>
                <p>(3) Putnik ima pravo neposredno od treće osobe odgovorne za štetu zahtijevati potpunu ili dopunsku
                    naknadu za pretrpljenu štetu.</p>
                <p>(4) U mjeri u kojoj je naknadio štetu putniku organizator putovanja stječe sva prava koja bi putnik
                    imao prema trećoj osobi odgovornoj za ovu štetu (pravo na regres).</p>
                <p>(5) Putnik je dužan ustupiti organizatoru putovanja isprave i sve što je potrebno za ostvarivanje
                    prava regresa.</p>
                <p>&nbsp;</p>
                <ol start="14">
                    <li><strong> Osiguranja </strong></li>
                </ol>
                <p><strong>14.1. Osiguranje od odgovornosti za štetu</strong></p>
                <p>(1) Organizator je za putovanja u paket-aranžmanu dužan s osiguravateljem sklopiti ugovor o
                    osiguranju od odgovornosti za štetu koju prouzroči putniku neispunjenjem, djelomičnim ispunjenjem
                    ili neurednim ispunjenjem obveza koje se odnose na paket-aranžman.</p>
                <p>(2) Organizator u ugovoru ili potvrdi ugovora o putovanju u paket-aranžmanu, koji ili koju mora
                    predati putniku, dužan je navesti podatke o osiguranju od odgovornosti za štetu, osiguranim
                    rizicima, o osiguratelju – društvu za osiguranje s pripadajućim osobnim identifikacijskim brojem,
                    adresom e-pošte i brojem telefona, broj izdane police osiguranja i druge podatke potrebne za
                    aktiviranje osiguranja i ostvarivanje prava na naknadu štete.</p>
                <p><strong>14.2. Osiguranje od rizika otkaza</strong></p>
                <p>(1) Putnik prilikom prijave za putovanje može u cilju izbjegavanja troškova otkaza putovanja &nbsp;Organizatoru
                    uplatiti i osiguranje od otkaza. Osiguranje od otkaza naplaćuje se samo prilikom sklapanja Ugovora
                    te ga nije moguće uplatiti naknadno.&nbsp; Premija osiguranja zavisi o vrijednosti putovanja, a
                    sukladno cjeniku Osiguravajućeg društva s kojim Organizator ima sklopljen ugovor o suradnji.
                    (Primjerice: kod najma apartmana dobiva se dijeljenjem cijene najma s brojem osoba u apartmanu)</p>
                <p>(2) Osiguranje od rizika otkaza ne uključuje:</p>
                <p>povrat uplaćene premije za osiguranje od otkaza, trošak pribave viza.</p>
                <p>(3) Ostali uvjeti osiguranja nalaze se uz policu osiguranja.</p>
                <p>&nbsp;</p>
                <p><strong>14.3. Osiguranja koja se moraju ponuditi Putniku</strong></p>
                <p>(1) Organizator je dužan ponuditi putniku osiguranje od posljedica nesretnog slučaja i bolesti na
                    putovanju, oštećenja i gubitka prtljage, dragovoljno zdravstveno osiguranje za vrijeme puta i
                    boravka u inozemstvu, osiguranje za slučaj otkaza putovanja te osiguranje kojim se osiguravaju
                    troškovi pomoći i povratka putnika u mjesto polazišta u slučaju nesreće i bolesti, staviti na
                    raspolaganje informaciju o sadržaju tih osiguranja te opće uvjete ugovora o osiguranju.</p>
                <p>(2) Osiguranje od rizika nesretnog slučaja i bolesti na putovanju, osiguranje od oštećenja i gubitka
                    prtljage kao i dragovoljno zdravstveno osiguranje, nisu uključena u cijenu putovanja</p>
                <p>(3) Potpisivanjem Ugovora o putovanju, čiji su sastavni dio i ovi Uvjeti smatra se da su Putnicima
                    ponuđena i preporučena dodatna osiguranja navedena u prethodnim stavcima.</p>
                <p>&nbsp;</p>
                <ol start="15">
                    <li><strong> Putne isprave</strong></li>
                </ol>
                <p>(1) Putnik je obvezan posjedovati važeće osobne putne isprave. Ukoliko bi zbog nevažećih putnih
                    isprava Putnik morao odustati od putovanja a zbog takvog propusta Putnika Organizator pretrpi štetu,
                    Putnik je dužan istu nadoknaditi.</p>
                <p>(2) Ukoliko za vrijeme putovanja dođe do gubitka ili krađe putnih isprava, troškove izdavanja novih
                    isprava snosi sam Putnik. Organizator će nastojati pomoći putniku u slučaju navedene situacije, ali
                    pritom vodeći računa o normalnom odvijanju programa putovanja.</p>
                <p>(3) Ako je za putovanje potrebna viza, Organizator može posredovati u pribavljanju iste uz posebnu
                    doplatu od strane Putnika, koja će se odrediti od slučaja do slučaja.</p>
                <p>&nbsp;</p>
                <ol start="16">
                    <li><strong> Devizni i carinski propisi</strong></li>
                </ol>
                <p>(1) Putnik je dužan &nbsp;pridržavati se deviznih i carinskih propisa, kao i zakona i drugih
                    podzakonskih propisa Republike Hrvatske i svih drugih zemalja kroz koje putuje u aranžmanu&nbsp;
                    Organizatora i u kojima borave.</p>
                <p>(2) U slučaju nemogućnosti nastavka putovanja radi kršenja propisa od strane putnika, sve nastale
                    troškove snosi sam prekršitelj.<strong>&nbsp;</strong></p>
                <p>(3) Organizator ne odgovara ni za odluke carinskih, policijskih i svih drugih nadležnih državnih
                    tijela kojima se Putniku ne dozvoljava ulazak u pojedinu zemlju.</p>
                <p>&nbsp;</p>
                <ol start="17">
                    <li><strong> Zdravstveni propisi</strong></li>
                </ol>
                <p>Putovanja u određene zemlje zahtijevaju obvezna cijepljenja protiv određenih bolesti. Prilikom
                    putovanja u te zemlje Putnik je obvezan posjedovati potvrde i dokumente o cijepljenju.</p>
                <p>&nbsp;</p>
                <ol start="18">
                    <li><strong> Prtljaga</strong></li>
                </ol>
                <p>(1) Prijevoz prtljage zrakoplovom je besplatan do mase koju određuje zrakoplovni prijevoznik. Višak
                    prtljage putnik plaća prema važećim cijenama zračnog prijevoznika.</p>
                <p>Djeca bez obzira na dob nemaju pravo na besplatan prijevoz prtljage u zračnom prijevozu.</p>
                <p>(2) Kod prijevoza autobusom putnik može ponijeti 2 komada osobne prtljage. Putnici su dužni voditi
                    brigu o svojim stvarima unijetim u kabinu autobusa, te su ih prilikom svakog napuštanja istog dužni
                    ponijeti sa sobom. U protivnom, putnici sami snose odgovornost za krađu, gubitak ili oštećenje
                    stvari ostavljenih u kabini autobusa bez njihovog nadzora.</p>
                <p><strong>&nbsp;</strong></p>
                <ol start="19">
                    <li><strong> Reklamacije</strong></li>
                </ol>
                <p>(1) Organizator preporuča da je u interesu Putnika da ukoliko ima reklamacije na pojedinu uslugu iz
                    aranžmana Organizatora svoj prigovor svakako upiše u knjigu žalbe kod davatelja usluga na licu
                    mjesta, kako bi davatelj usluge pružio ugovorenu uslugu, odnosno ispravio pogreške.</p>
                <p>(2) Ukoliko ni nakon prigovora ne bude poboljšanja u pružanju usluge, Putnik mora tražiti potvrdu iz
                    koje se vidi da usluga nije pružena, odnosno da nije pružena na način kako je ugovoreno. Putnik mora
                    potvrdu priložiti prilikom pisane reklamacije Organizatoru. Pisanu reklamaciju putnik je dužan
                    uložiti u roku od 8 dana nakon završetka putovanja.&nbsp; Ukoliko Putnik uloži pismenu reklamaciju
                    nakon tog roka, Organizator takvu reklamaciju nije dužan uzeti u obzir.&nbsp;Za rješavanje
                    reklamacije na pružene usluge u inozemstvu, Putnik obvezno treba poštivati pravilo prijave prigovora
                    uz izdavanje potvrde o učinjenom prigovoru na licu mjesta i rok za podnošenje reklamacije.</p>
                <p>&nbsp;</p>
                <p>(3) Organizator je dužan pisano odgovoriti na Prigovor Putnika u roku od 14 dana od primitka
                    prigovora, a može odgoditi rok za pisani odgovor na prigovor za dodatnih 14 dana zbog prikupljanja
                    informacija. Organizator će rješavati samo one pritužbe koje se nisu mogle otkloniti na mjestu
                    odmora. Dok postupak po prigovoru traje, 14, odnosno najviše 28 dana od ulaganja prigovora, Putnik
                    se neopozivo odriče posredovanja bilo koje druge osobe, arbitraže UHPA-e ili druge institucije, kao
                    i davanja informacija u medije.&nbsp;Isto tako u ovom vremenu Putnik odriče prava na tužbu. Najviši
                    iznos nadoknade po prigovoru može doseći iznos reklamiranog dijela usluga, a ne može obuhvatiti već
                    iskorištene usluge kao ni cjelokupni iznos aranžmana.</p>
                <p>(4) Vezano za reklamaciju na usluge paket aranžmana na plovnim objektima nautičkog turizma u koji je
                    uključen najam plovila, Organizator će prihvatiti samo one reklamacije koje su podnesene u pismenom
                    obliku i to najkasnije prilikom vraćanja plovila, te ako su potpisane osobno od strane Putnika.</p>
                <p>(5) U slučaju sklapanja ugovora po «Fortuna sistemu» Putnik nema pravo prigovora na kvalitetu&nbsp;
                    usluge smještaja.</p>
                <p>(6) Putnik i Organizator će sporove nastojati razriješiti sporazumno, a u suprotnom ugovaraju
                    nadležnost suda u Rijeci i primjenu hrvatskog prava.</p>
                <p>&nbsp;</p>
                <ol start="20">
                    <li><strong> Zaštita osobnih podataka Putnika</strong></li>
                </ol>
                <p>(1) Putnik osobne podatke daje dobrovoljno i Putnik potpisom Ugovora o putovanju u paket aranžmanu
                    daje privolu da se njeni osobni podaci mogu koristiti u svrhu marketinških akcija Organizatora.</p>
                <p>(2) Obavijesti koje dobije o Putniku, njegovoj prtljazi, njegovim kretanjima i imenima njegovih
                    suputnika Organizator smije priopćiti trećim osobama samo s posebnom privolom putnika ili na zahtjev
                    nadležnog tijela javne vlasti te u svrhu realizacije ugovorenih usluga putovanja, ugovaranje
                    osiguranja od rizika otkaza i osiguranja od posljedica nesretnog slučaja i bolesti, osiguranje od
                    rizika oštećenja i gubitka prtljage i dragovoljno zdravstveno osiguranje za vrijeme puta i boravka u
                    inozemstvu.</p>
                <p>(3) Osobni podaci putnika čuvati će se u bazi podataka Organizatora, sukladno odluci Uprave o načinu
                    prikupljanja, obrade i čuvanja osobnih podataka.</p>
                <p>&nbsp;</p>
                <ol start="21">
                    <li><strong> Završne odredbe</strong></li>
                </ol>
                <p>(1) Opći Uvjeti sastavni su dio Ugovora o putovanju u paket aranžmanu kojeg Putnik sklapa s
                    Organizatorom te potpisivanjem Ugovora Putnik u cijelosti prihvaća program i uvjete putovanja u
                    paket aranžmanu.</p>
                <p>(2) Moguća odstupanja od ovih Uvjeta, moraju biti navedena uz tekst programa putovanja, ukoliko se
                    radi o turoperatorima – inozemnim organizatorima putovanja.</p>
                <p>&nbsp;</p>
                <p>U Rijeci, 29.05.2018.</p>
                <p>Meraki d.o.o.</p>
                <p>&nbsp;</p>
            </div>
        </div>
    </div>
</template>

<script>

</script>

<style lang="scss" scoped>
@import "../../../scss/variables.scss";

.navigationMargin {
    @media screen and (min-width: 768px) {
        margin-left: 80px;
        padding-bottom: 30px;
    }
    a{
        text-decoration: none;
        color: $secondary;
    }
    padding-bottom: 80px;
}
</style>
